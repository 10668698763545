/*.App {*/
/*  text-align: center;*/
/*  color: black;*/
/*}*/


html,
body, .App {
    margin: 0;
    color: whitesmoke;
}

.App {

}

